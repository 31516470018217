// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application.scss'
import '../src/application'

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "foundation-sites/dist/js/foundation"
import MotionUI from "motion-ui/dist/motion-ui"
import "select2/dist/js/select2"
import "chartkick"
import "chart.js"

Rails.start()
ActiveStorage.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// https://www.mskog.com/posts/google-analytics-gtag-with-rails-5-and-turbolinks/
document.addEventListener('turbo:load', event => {
  if (typeof gtag === 'function') {
    gtag('config', window.gaTrackingId, {
      'page_location': event.detail.url
    });
  }
});

$(document).on('turbo:load', () => {
  $(document).foundation();

  $('select.helpful').select2();

  // https://www.thewebflash.com/how-to-add-a-cross-browser-add-to-favorites-bookmark-button-to-your-website/
  $('#add-to-home-screen').click(() => {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const android = !!ua.match(/iAndroid/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i); // CriOS is to exclude chrome running on iOS

    if ('addToHomescreen' in window && addToHomescreen.isCompatible) {
      // Mobile browsers that support the official "Add to Home Screen" functionality (using PWAs)
      // https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen
      // but we are not compatible with this yet!
      addToHomescreen({ autostart: false, startDelay: 0 }).show(true);
    } else if (iOSSafari) {
      alert('To add to Home Screen, tap the Share button and select "Add to Home Screen".');
    } else if (iOS) {
      // Can only add to home screen through Safari on iOS
      alert('To add to Home Screen, launch this website in Safari, then tap the Share button and select "Add to Home Screen".');
    } else if (android) {
      // Assuming their browser is Chrome
      alert('To add to Home Screen, tap the menu icon (3 dots in upper right-hand corner) and tap "Add to Home Screen"');
    } else {
      // Other browsers, most likely desktop (Chrome, Safari, Firefox 63+, Opera 15+)
      alert('Press ' + (/Mac/i.test(navigator.platform) ? 'Cmd' : 'Ctrl') + '+D to bookmark this page.');
    }
    return false;
  });
 });
